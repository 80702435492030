(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*global $, jQuery, alert, console*/

    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    /*var Persona = {
        api_url: "https://www.altoroslabs.com/api.html"
    };
    $("form#bottomForm").on("submit", function (event) {
        event.preventDefault();
        var sendBtn = $(this).find('.submitButton');
        var preloaderBtn = $(this).find('.preloader-wrapper-btn');

        if(!$(this).hasClass('notvalid')) {
            sendBtn.hide();
            preloaderBtn.show();
            //need validate if Ok:

            // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};

            $.post(Persona.api_url, $(this).serialize()).done(function () {
                switch (window.location.origin) {
                    case "https://www.altoroslabs.com" :
                        location.href = 'http://www.altoroslabs.com/thanks-for-contacting-persona';
                        break;
                    case  "https://www.altoros.fi" :
                        location.href = 'http://www.altoros.fi/thanks-for-contacting-persona';
                        break;
                }
                sendBtn.show();
                preloaderBtn.hide();
            }).fail(function () {
                sendBtn.show();
                preloaderBtn.hide();
                alert('Sorry, an error has occurred, please try again!');
            });
            return false;
        }

    });*/


    $(document).ready(function () {
        $('.s-header__text-wrap_slider').show();
        controlTopMenu();
        slickSliderControl();
        controlAnimatedScroll();
        detectSeenElements();
        copyOnClipboard();
        customAboutTabController();
        customTabController();
        customPrtfolioTabController();
        showMoreBtnController();
        addInlineSvg();
        controlAgileArrow();
        contactFormsConfig();
        blogTagsController();
        controlCalculator();
        controlVacCalculator();

        $('.sidenav').sidenav();

        $('select').formSelect();

        $('.tooltipped').tooltip();
        $('.collapsible').collapsible();
        $('.modal').modal();
        $('.dropdown-link_material').dropdown();
        $('.tabs').tabs();
        $('.lazy').Lazy();
        $('.s-case-description-content_fixto').fixTo('.s-case-description__content-wrap', {
            top: 120
        });
        $('.s-blog-list__baners').fixTo('.s-blog-list__content', {
            top: 120
        });
        $('.s-benefits__block--left').fixTo('.s-benefits__content', {
            top: 120
        });
        $('.s-faq__part--right').fixTo('.s-faq__content', {
            top: 120
        });


        $('body').on('click','.gdpr-cookie-notice-modal-footer-item-save', function () {
            $('.gdpr-cookie-notice-modal').css('display', 'none');
            $('.gdpr-cookie-notice').css('display', 'none');
        });

        $('body').on('click','.gdpr-cookie-notice-modal-footer-item-statement', function () {
            $('#privacy_popup').modal('open');
        });

        $('#videoCustomers').on('click', function() {
            $(this).closest('.video-wrap').addClass('played');
            if(!$(this).hasClass('played')) {
                this.play();
            }
            $(this).addClass('played');
        });

        if(isMobile.any()) {
            $('#videoCustomers').closest('.video-wrap').addClass('played');
        }




        function controlCalculator() {

            $('.s-calculator__block-item').on('click', function () {
                var checked = false;
                if ($(this).closest('.s-calculator__block').hasClass('radioBlock')) {
                    $(this).closest('.s-calculator__block').find('.s-calculator__block-item').each(function () {
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                } else {
                    $(this).toggleClass('active');
                }

                $(this).closest('.s-calculator__block').find('.s-calculator__block-item').each(function () {
                    if ($(this).hasClass('active')) {
                        checked = true;
                    }
                });

                if (checked) {
                    $(this).closest('.s-calculator__block').addClass('checked');
                } else {
                    $(this).closest('.s-calculator__block').removeClass('checked');
                }
            });

            $('.next-btn').on('click', function (e) {
                e.preventDefault();
                $(this).closest('.s-calculator__block').removeClass('active').next('.s-calculator__block').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorSection').offset().top - 86
                }, 500);
            });
            $('.skip-btn').on('click', function (e) {
                e.preventDefault();
                $(this).closest('.s-calculator__block').removeClass('active').next('.s-calculator__block').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorSection').offset().top - 86
                }, 500);
            });
            $('.prev-btn').on('click', function (e) {
                e.preventDefault();
                $(this).closest('.s-calculator__block').removeClass('active').prev('.s-calculator__block').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorSection').offset().top - 86
                }, 500);
            });

            $('#calculatorStep1 .s-calculator__block-item').on('click', function () {
                if ($('#calculatorStep1 .s-calculator__block-item:first-child').hasClass('active')) {
                    $('#calculatorSection').addClass('business');
                } else {
                    $('#calculatorSection').removeClass('business');
                }

            })

        }

        function controlVacCalculator() {
            $('.s-calculator-vac__block-item.check-item').on('click', function () {
                var checked = false;
                if ($(this).closest('.s-calculator-vac__block').hasClass('radioBlock')) {
                    $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item').each(function () {
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                } else {
                    $(this).toggleClass('active');
                }

                $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item').each(function () {
                    if ($(this).hasClass('active')) {
                        checked = true;
                    }
                });

                if (checked) {
                    $(this).closest('.s-calculator-vac__block').addClass('checked');
                } else {
                    $(this).closest('.s-calculator-vac__block').removeClass('checked');
                }
            });

            $('.s-calculator-vac__block-item--yes').on('click', function (e) {
                e.preventDefault();
                $('.s-calculator-vac__block--first .formWP').addClass('hidden')

                $('.s-calculator-vac__block-line').each(function () {
                    $(this).removeClass('active');
                });
                $('.s-calculator-vac__block-line--yes').addClass('active');
            })

            $('.s-calculator-vac__block-item--no').on('click', function (e) {
                e.preventDefault();
                $('.s-calculator-vac__block--first .formWP').removeClass('hidden')


                $('.s-calculator-vac__block-line').each(function () {
                    $(this).removeClass('active');
                });
                $('.s-calculator-vac__block-line--no').addClass('active');
            });




            $('.s-calculator-vac__block--first .next-btn-vac').on('click', function (e) {
                e.preventDefault();
                if ($('.s-calculator-vac__block--first').hasClass('checked')) {

                    $(this).closest('.s-calculator-vac__block').removeClass('active');
                    if ($('.s-calculator-vac__block-item--yes').hasClass('active')) {
                        $('.s-calculator-vac__block-line').each(function () {
                            $(this).removeClass('active');
                        });
                        $('.s-calculator-vac__block-line--yes').addClass('active');
                    }
                    if ($('.s-calculator-vac__block-item--no').hasClass('active')) {
                        $('.s-calculator-vac__block-line').each(function () {
                            $(this).removeClass('active');
                        });
                        $('.s-calculator-vac__block-line--no').addClass('active');
                    }

                    $('.s-calculator-vac__block-line.active').find('.s-calculator-vac__block-normal--first').addClass('active');

                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                }
            });

            $('.s-calculator-vac__block-normal .next-btn-vac').on('click', function (e) {
                e.preventDefault();
                if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--last')) {
                    $(this).closest('.s-calculator-vac__block-normal--last').removeClass('active');
                    $('.s-calculator-vac__block--form').addClass('active')
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                } else {
                    $(this).closest('.s-calculator-vac__block-normal').removeClass('active').next('.s-calculator-vac__block-normal').addClass('active');
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                }
            });
            $('.s-calculator-vac__block-normal .skip-btn-vac').on('click', function (e) {
                e.preventDefault();
                if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--last')) {
                    $(this).closest('.s-calculator-vac__block-normal--last').removeClass('active');
                    $('.s-calculator-vac__block--form').addClass('active')
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                } else {
                    $(this).closest('.s-calculator-vac__block-normal').removeClass('active').next('.s-calculator-vac__block-normal').addClass('active');
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                }
            });
            $('.s-calculator-vac__block-normal .prev-btn-vac').on('click', function (e) {
                e.preventDefault();
                if ($(this).closest('.s-calculator-vac__block-normal').hasClass('s-calculator-vac__block-normal--first')) {

                    $(this).closest('.s-calculator-vac__block-normal').removeClass('active').closest('.s-calculator-vac__block-line').removeClass('active');
                    $('.s-calculator-vac__block--first').addClass('active')
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                } else {
                    $(this).closest('.s-calculator-vac__block-normal').removeClass('active').prev('.s-calculator-vac__block-normal').addClass('active');
                    $('html, body').animate({
                        scrollTop: $('#calculatorVacSection').offset().top - 86
                    }, 500);
                }
            });
            $('.s-calculator-vac__block--form .prev-btn-vac').on('click', function (e) {
                e.preventDefault();

                $(this).closest('.s-calculator-vac__block').removeClass('active');
                $('.s-calculator-vac__block-line.active').find('.s-calculator-vac__block-normal--last').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#calculatorVacSection').offset().top - 86
                }, 500);
            })


            $('.s-calculator-vac__minus-btn').on('click', function (e) {
                e.preventDefault();

                var curNum = +$(this).next('.s-calculator-vac__select-res').text();
                if (curNum != 0) {
                    curNum -= 1;
                    $(this).next('.s-calculator-vac__select-res').text(curNum);
                }
            })
            $('.s-calculator-vac__plus-btn').on('click', function (e) {
                e.preventDefault();

                var curNum = +$(this).prev('.s-calculator-vac__select-res').text();
                curNum += 1;
                $(this).prev('.s-calculator-vac__select-res').text(curNum);
            })


            $('.s-calculator-vac__select-calc-add-btn').on('click', function (e) {
                e.preventDefault();

                if (+$(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text() != 0) {
                    var curName = $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-item-name').text();
                    var curPos = $(this).closest('.s-calculator-vac__select-item').find('select').val();
                    var curNumber = $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text();
                    $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text('0')


                    $(this).closest('.s-calculator-vac__block').find('.s-calculator-vac__block-item--select-results-wrap').append("<div class='s-calculator-vac__block-item--select-result'><span>" + curName + " (" + curPos + ") - " + curNumber + " specialist</span><a href='#' class='s-calculator-vac__remove-result'><img src='assets/images/svg/cross-icon.svg'></a></div>")
                }

                $(this).closest('.s-calculator-vac__block').addClass('checked');

            })

            $('.s-calculator-vac__select-item-top select').on('change', function () {
                $(this).closest('.s-calculator-vac__select-item').find('.s-calculator-vac__select-res').text('0')
            })

            $('#calculatorVacSection').on('click', '.s-calculator-vac__remove-result', function (e) {
                e.preventDefault();

                $(this).closest('.s-calculator-vac__block-item--select-result').remove();
            })


            if ($('#calculatorVacRange').length) {
                var slider = document.getElementById('calculatorVacRange');
                noUiSlider.create(slider, {
                    start: 0,
                    connect: 'lower',
                    tooltips: [true],
                    step: 1,
                    orientation: 'horizontal', // 'horizontal' or 'vertical'
                    range: {
                        'min': 0,
                        'max': 24
                    },
                    format: wNumb({
                        decimals: 0
                    }),
                    pips: {
                        mode: 'values',
                        values: [6, 12, 18, 24],
                        density: 4,
                        format: wNumb({
                            decimals: 0,
                            suffix: ' months'
                        })
                    }
                });
            }



        }


        function blogTagsController() {
            $('.s-blog-list__tag').on('click', function () {
                $('.s-blog-list__tag.active').removeClass('active');
                $(this).addClass('active');
            })
        }

        function customPrtfolioTabController() {

            if(window.location.pathname === '/stories.html' || window.location.pathname === '/stories') {
                var hash = window.location.hash;

                if(hash !== '') {
                    $('html, body').animate({
                        scrollTop: $('#portfolioSection').offset().top - 86
                    }, 500);

                    $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
                        $(this).removeClass('active');
                    });

                    $("div[data-tab='industries']").eq(0).addClass('active');

                    var curCat = 'industries';

                    $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
                        $(this).removeClass('active');
                        if($(this).attr('data-tab') == curCat) {
                            $(this).addClass('active');
                        }
                    });
                    $('.s-portfolio__tags-wrap .tags-block').each(function () {
                        $(this).removeClass('active');
                        if($(this).attr('data-tab') == curCat) {
                            $(this).addClass('active');
                        }
                    });
                    $('.tags-block .tag').each(function () {
                        $(this).removeClass('active');

                        if(hash) {
                            $("div[data-anchor='" + hash + "']").addClass('active');
                            setBlocks($("div[data-anchor='" + hash + "']"));
                        }
                    });
                }
            }

            controlShowMore();

            function controlShowMore() {
                var projectCounter = 0;
                var showProjectCounter = 6;
                var stepShowProjects = 6;

                function controlShowMoreBtn() {
                    if(projectCounter <= showProjectCounter) {
                        $('.show-more__link_portfolio').hide();
                    } else {
                        $('.show-more__link_portfolio').show();
                    }
                }

                function controlShowProjects() {
                    $('.projects-wrap .project-block.active').each(function () {
                        if($(this).attr('data-count') > showProjectCounter) {
                            $(this).hide();
                        } else {
                            $(this).show();
                        }
                    });
                }

                $('.projects-wrap .project-block.active').each(function () {
                    projectCounter ++;
                    $(this).attr('data-count', projectCounter);
                });


                controlShowProjects();
                controlShowMoreBtn();


                $('.show-more__link_portfolio').on('click', function (e) {
                    e.preventDefault();
                    showProjectCounter = showProjectCounter + stepShowProjects;

                    controlShowProjects();
                    controlShowMoreBtn();
                })

            }


            $('.s-portfolio__tabs-wrap .s-portfolio__tab').on('click', function () {
                var curTab = $(this).attr('data-tab');
                $('.s-portfolio__tabs-wrap .s-portfolio__tab').each(function () {
                    $(this).removeClass('active');
                    if($(this).attr('data-tab') == curTab) {
                        $(this).addClass('active');
                    }
                });
                $('.s-portfolio__tags-wrap .tags-block').each(function () {
                    $(this).removeClass('active');
                    $(this).find('.tag_all').addClass('active');
                    if($(this).attr('data-tab') == curTab) {
                        $(this).addClass('active');
                    }
                });
                $('.tags-block .tag').each(function () {
                    if(!$(this).hasClass('tag_all')) {
                        $(this).removeClass('active');
                    }
                });
                $('.projects-wrap .project-block').each(function () {
                    $(this).addClass('active');
                });

                controlShowMore();
            });



            $('.tags-block .tag').on('click', function() {
                var anchor = $(this).attr('data-anchor');

                if (typeof anchor !== typeof undefined && anchor !== false) {
                    history.pushState(null, null, anchor);
                } else {
                    history.pushState(null, null, window.location.pathname);
                }

                if($(this).hasClass('tag_all')) {
                    $('.projects-wrap .project-block').each(function () {
                        $(this).addClass('active');
                    });
                    $(this).closest('.tags-block').find('.tag').each(function () {
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                    controlShowMore();
                    return;
                }

                setBlocks($(this));

                controlShowMore();
            });

            function setBlocks(_this) {
                var curTag = _this.find('span').text();
                _this.closest('.tags-block').find('.tag').each(function () {
                    $(this).removeClass('active');
                });

                _this.addClass('active');
                $('.projects-wrap .project-block').each(function () {
                    var thisBlock = $(this);
                    var flag = false;
                    thisBlock.find('.tags-wrap').find('.tag').each(function () {
                        if($(this).find('span').text() == curTag) {
                            flag = true;
                        }
                    });
                    if (flag && !thisBlock.hasClass('active')) {
                        thisBlock.addClass('active');
                    } else if (!flag) {
                        thisBlock.removeClass('active');
                    }
                });
            }

        }

        function controlAgileArrow() {
            $('.chose-link').on('click', function(e) {
                e.preventDefault();
                clearInterval(blinkSWRok);
                $('.s-arrow-work-plan').removeClass('slow-delay');
                $('.s-arrow-work-plan').removeClass('blink');
                var curId = $(this).attr('href').slice(1);
                $('.chose-link').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('href').slice(1) == curId) {
                        $(this).addClass('active');
                    }
                });
                $('.chose-link-res').each(function () {
                    $(this).removeClass('active');
                    if($(this).attr('id') == curId) {
                        $(this).addClass('active');
                    }
                });
            });
            $('.chose-link-scale').on('click', function(e) {
                e.preventDefault();
                var curId = $(this).attr('href').slice(1);
                $('.chose-link-scale').each(function() {
                    $(this).removeClass('active');
                });
                $('.chose-link-res-scale').each(function () {
                    $(this).removeClass('active');
                    if($(this).attr('id') == curId) {
                        $(this).addClass('active');
                    }
                });
                $(this).addClass('active');
            });
            var blinkSWRok = setInterval(function() {
                $('.s-arrow-work-plan').toggleClass('blink');
            },1000);

            $('.s-arrow-work-wrap-mobile').slick({
                variableWidth: true,
                arrows: false,
                centerMode: false,
                infinite: false,
                focusOnSelect: true
            });
        }


        function showMoreBtnController(){
            $('.show-more__link').on('click', function (e) {
                e.preventDefault();
                $(this).addClass('active');
                $(this).closest('section').addClass('show-more_active');
            })
        }



        function customTabController() {
            $('.custom-tabs .tab__link').on('click', function() {
                var currentTab = $(this).attr('data-tab');
                $(this).closest('.custom-tabs').find('.tab__link').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('data-tab') == currentTab) {
                        $(this).addClass('active');
                    }
                });
                $(this).closest('.custom-tabs').find('.tab__result').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('data-tab') == currentTab) {
                        $(this).addClass('active');
                        if($(window).width() <= 600) {
                            $('html, body').animate({
                                scrollTop: $(this).offset().top - 80
                            }, 500);
                        }
                    }
                })
            })
        }
        
        function customAboutTabController() {
            $('.s-about .tab__link').on('click', function() {
                var checkedCountry = $(this).attr('data-country');
                $('.tabs__links-wrap .tab__link').each(function() {
                    $(this).removeClass('active');
                    if ($(this).attr('data-country') == checkedCountry) {
                        $(this).addClass('active');
                    }
                });
                $('.tabs__results-wrap .tab__result').each(function() {
                    $(this).removeClass('active');
                    if ($(this).attr('data-country') == checkedCountry) {
                        $(this).addClass('active');
                    }
                });
                $(this).closest('.s-about').attr('data-country', checkedCountry);

            })
        }


        function copyOnClipboard() {
            if (isSafari) {
                $('.copy-block').each(function () {
                    $(this).hide();
                })
            }
            $('.copy-block').on('click', function() {
                var copyText = $(this).prev('a').text();
                navigator.clipboard.writeText(copyText);
                var element = $(this);
                element.addClass('active');
                element.addClass('active-copy');
                setTimeout(function(){ element.removeClass('active'); }, 200);
                setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
            })
        }



        function detectSeenElements() {
            var sections = $('section, .animated-block');

            sections.each(function () {
                var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                    $(this).addClass('seen')
                } else {
                    // the element is not visible, do something else
                }
            })

            $(window).scroll(function() {

                sections.each(function () {
                    var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                    var top_of_screen = $(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                        $(this).addClass('seen')
                    } else {
                        // the element is not visible, do something else
                    }
                })
            });
        }

        function controlTopMenu() {
            var scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.menu').addClass('not-top');
            } else {
                $('.menu').removeClass('not-top');
            }
            $(window).scroll(function() {
                scrollHeight = $(window).scrollTop();

                if(scrollHeight  > 0) {
                    $('.menu').addClass('not-top');
                } else {
                    $('.menu').removeClass('not-top');
                }
            });

            $('.desktop-menu .dropdown-trigger').on('click', function(e){
                e.preventDefault();
                if($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $('.desktop-menu .dropdown-menu').each(function() {
                        $(this).removeClass('active');
                    });
                    return
                }
                var curMenu = $(this).attr('href').slice(1);
                $('.desktop-menu .dropdown-trigger').each(function() {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
                $('.desktop-menu .dropdown-menu').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('id') == curMenu) {
                        $(this).addClass('active');
                    }
                })
            });
            $(document).click(function(event) {
                var target = $(event.target);
                if(!target.closest('.dropdown-trigger').length && !target.hasClass('dropdown-trigger') && !target.closest('.dropdown-menu').length && !target.hasClass('dropdown-menu')) {
                    $('.desktop-menu .dropdown-menu').each(function() {
                        $(this).removeClass('active');
                    });
                    $('.desktop-menu .dropdown-trigger').each(function() {
                        $(this).removeClass('active');
                    });
                }
            });

            $(window).resize(function() {

                if ($(window).width() <= 991) {
                    $('.desktop-menu .dropdown-menu').each(function() {
                        $(this).removeClass('active');
                    })
                    $('.desktop-menu .dropdown-trigger').each(function() {
                        $(this).removeClass('active');
                    });
                }

            });


            $('.dropdown-menu .dropdown-menu__link').on('mouseenter', function () {
                var curIndex = $(this).attr('data-index');
                $(this).closest('.dropdown-menu__links-wrap').prev('.dropdown-menu__images-wrap').find('.intro-block').each(function () {
                    $(this).removeClass('active')
                    if($(this).attr('data-index') == curIndex) {
                        $(this).addClass('active')
                    }
                })
            });


        }



        function controlAnimatedScroll() {
            $('.animated-scroll').on('click', function (e) {
                e.preventDefault();
                var linkHref = $(this).attr('href');
                $('html, body').animate({
                    scrollTop: $(linkHref).offset().top - 60
                }, 500);
            });

            $('.back-top__link').on('click', function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            });
        }


        function slickSliderControl() {
            $('.partners-wrap').slick({
                variableWidth: true,
                arrows: false,
                centerMode: false,
                infinite: true,
                dots: false,
                autoplay: true,
                cssEase: 'linear',
                autoplaySpeed: 0,
                speed: 1500,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false
            });
            $('.partners-wrap').css('width',$('body').innerWidth());

            $('.s-header__text-wrap_slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: false,
                infinite: true,
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 500,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                fade: true,
                cssEase: 'linear',
            });


            $('.s-customers__slider').slick({
                arrows: true,
                autoplay: true,
                autoplaySpeed: 3000,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>'
            });


            $('.s-blog-case__slider').slick({
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            $('.s-portfolio-case-slider__slider').slick({
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.s-cases__slider').slick({
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            $('.s-case-description__block_slider').slick({
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>'
            });

            $('.s-speak__slider').slick({
                arrows: true,
                slidesToShow: 1,
                variableWidth: true,
                slidesToScroll: 1,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/svg/arrow-slider_rght.svg"></div>',
                centerMode: false,
                centerPadding: '200px',
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            centerPadding: '100px'
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            asNavFor: '.s-speak_tabs-wrap',
                            centerPadding: '0'
                        }
                    }
                ]
            });

            $('.s-speak_tab').on('click', function(e) {
                e.preventDefault();
                var slideIndex = $(this).attr('data-index');
                $('.s-speak_tab').each(function() {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
                $('.s-speak__slider').slick('slickGoTo', slideIndex);
            });

            $(".s-speak__slider").on('afterChange', function(){
                var curIndex = $('.s-speak__slider').slick('slickCurrentSlide');
                $('.s-speak_tab').each(function() {
                    $(this).removeClass('active');
                    if($(this).attr('data-index') == curIndex){
                        $(this).addClass('active')
                    };
                });
            });






            if ($(window).width() <= 600) {
                $('.s-speak_tabs-wrap').slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    asNavFor: '.s-speak__slider',
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true,
                    arrows: false,
                    responsive: [
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 400,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            }


            if ($(window).width() <= 767) {
                $('.carousel.carousel-slider').carousel({
                    fullWidth: true,
                    indicators: true,
                    onCycleTo: function(data) {
                        var checkedCountry = $(data).attr('data-country');
                        $(data).closest('.s-about').attr('data-country', checkedCountry);
                    }
                });
                $('.carousel.carousel-slider').addClass('init-slider');

                $('.s-portfolio__tabs-wrap').slick({
                    arrows: false,
                    dots: false,
                    variableWidth: true,
                    infinite: false
                });
            }






            $(window).resize(function() {
                addInlineSvg();

                $('.partners-wrap').css('width',$('body').innerWidth());


                if ($(window).width() <= 767 && !$('.carousel.carousel-slider').hasClass('init-slider')) {
                    $('.carousel.carousel-slider').carousel({
                        fullWidth: true,
                        indicators: true,
                        onCycleTo: function(data) {
                            var checkedCountry = $(data).attr('data-country');
                            $(data).closest('.s-about').attr('data-country', checkedCountry);
                        }
                    });
                    $('.carousel.carousel-slider').addClass('init-slider');
                }
                if ($(window).width() <= 767 && !$('.s-portfolio__tabs-wrap').hasClass('slick-slider')) {
                    $('.s-portfolio__tabs-wrap').slick({
                        arrows: false,
                        dots: false,
                        variableWidth: true,
                        infinite: false
                    });
                }

                if ($(window).width() <= 600 && !$('.s-speak_tabs-wrap').hasClass('slick-slider')) {
                    $('.s-speak_tabs-wrap').slick({
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        asNavFor: '.s-speak__slider',
                        dots: false,
                        centerMode: true,
                        focusOnSelect: true,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 500,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                }
                            },
                            {
                                breakpoint: 400,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                }
                            }
                        ]
                    });
                }



            });

            $('.s-header-slider__slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: false,
                infinite: true,
                dots: true,
                autoplay: true,
                autoplaySpeed: 7000,
                speed: 500,
                draggable: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                fade: true,
                cssEase: 'linear',
            });

            $('.s-contact-customers__customers-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="assets/images/img/arrow-new-left.png"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="assets/images/img/arrow-new-right.png"></div>',
                centerMode: false,
                infinite: true,
                dots: false,
                autoplay: false,
                speed: 500,
                draggable: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                adaptiveHeight: true,
                fade: true,
                cssEase: 'linear',
            });

            $('.s-our-customers-slider__slider').slick({
                variableWidth: true,
                arrows: false,
                centerMode: false,
                infinite: true,
                dots: false,
                autoplay: true,
                cssEase: 'linear',
                autoplaySpeed: 0,
                speed: 2500,
                draggable: false,
                pauseOnFocus: false,
                pauseOnHover: false
            });
        }

        function speak_slider() {
            if ($(window).width() <= 1280 && !$('.s-clients__blocks-wrap').hasClass('slick-slider')) {
                $('.s-clients__blocks-wrap').slick({
                    variableWidth: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: true,
                    cssEase: 'linear',
                    speed: 300,
                    pauseOnFocus: false,
                    pauseOnHover: false,
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            } else if ($(window).width() > 1280 && $('.s-clients__blocks-wrap').hasClass('slick-slider')) {
                $('.s-clients__blocks-wrap').slick('unslick');
            }
        }

        speak_slider();
        $(window).resize(function() {
            speak_slider();
        });

        function addInlineSvg() {
            var mySVGsToInject = document.querySelectorAll('img.image_svg');
            SVGInjector(mySVGsToInject);
        }


        function contactFormsConfig() {
            try {
                $('input[name=url_page]').val(location.origin + location.pathname);
                $('input[name=lead_source_description]').val('AltorosLabs | '+ location.pathname);
            }catch (e) {

            }
        }

        $('.s-delivers__plus-btn').on('click', function () {
            $(this).closest('.s-delivers__item').toggleClass('active')
        })

        $('.s-technology-business-2__block').on('click', function () {
            $(this).closest('.s-technology-business-2').find('.s-technology-business-2__block').each(function () {
                $(this).removeClass('active');
            })
            $(this).addClass('active');
        })

    });
    // gdprCookieNotice({
    //     locale: 'en', //This is the default value
    //     timeout: 500, //Time until the cookie bar appears
    //     expiration: 60, //This is the default value, in days
    //     domain: '.altoros.no', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
    //     implicit: false, //Accept cookies on page scroll automatically
    //     statement: '#', //Link to your cookie statement page
    //     performance: ['JSESSIONID', '_hjIncludedInSample', 'nQ_visitId', 'nQ_cookieId', 'locale', 'lang', '__atuvc', '__hstc', '__hssrc', '__hssc', 'hsfirstvisit', 'hubspotutk'], //Cookies in the performance category.
    //     analytics: ['ga', '_ga', '_gid', '_gat', 'collect', '_ga_user_id', '__utmz', '__utma', 'gwcc'], //Cookies in the analytics category.
    //     marketing: ['SSID', 'IDE', 'test_cookie', 'fr', 'tr', '1P_JAR', 'id', 'NID', 'APISID', 'SAPISID', 'guest_id', 'personalization_id', 'bcookie', 'bscookie', 'lidc', 'MUID', 'MUIDB', 'GPS'] //Cookies in the marketing category.
    // });


    // $(function() {
    //     $.get("https://ipinfo.io/json", function (response) {
    //         // if(response.country === "GB"){
    //         //     $('a.phone-link').removeClass('uk-link');
    //         // } else
    //         if (response.country === "US") {
    //             $('a.phone-link').removeClass('usa-link');
    //             $('.s-about-altoros-bottom').addClass('usa-block_hidden');
    //         }
    //     }, "jsonp");
    // });
},{}]},{},[1]);
