(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(document).ready(function () {
    let source, medium, term, content, campaign, session_count, pageview_count;

    function get_campaign_info() {
        var utma = get_utm_value(document.cookie, '__utma=', ';');
        var utmb = get_utm_value(document.cookie, '__utmb=', ';');
        var utmc = get_utm_value(document.cookie, '__utmc=', ';');
        var utmz = get_utm_value(document.cookie, '__utmz=', ';');

        source = get_utm_value(utmz, 'utmcsr=', '|');
        medium = get_utm_value(utmz, 'utmcmd=', '|');
        term = get_utm_value(utmz, 'utmctr=', '|');
        content = get_utm_value(utmz, 'utmcct=', '|');
        campaign = get_utm_value(utmz, 'utmccn=', '|');

        session_count = get_session_count(utma);
        pageview_count = get_pageview_count(utmb, utmc);
    }

    function get_utm_value(l, n, s) {
        if (!l || l == "" || !n || n == "" || !s || s == "") return "-";
        var i, j, k, utm = "-";
        i = l.indexOf(n);
        k = n.indexOf("=") + 1;

        if (i > -1) {
            j = l.indexOf(s, i);
            if (j < 0) {
                j = l.length;
            }
            utm = l.substring((i + k), j);
        }
        return utm;
    }

    //This function extracts the "Count of Sessions" value from the _utma cookie
    function get_session_count(str) {
        var i, vc = '-';
        if (str != '-') {
            i = str.lastIndexOf(".");
            i++;
            vc = str.substring(i);
        }
        return vc;
    }

    //This function extracts the "Count of Pageviews" value from the _utmb cookie
    function get_pageview_count(utmb, utmc) {
        var i, j, pc = '-';
        if (utmb != '-' && utmc != '-') {
            utmc = utmc + '.';

            i = utmc.length;
            j = utmb.indexOf(".", i);
            pc = utmb.substring(i, j);
        }
        return pc;
    }

    function getCookie(name) {
        const escapedName = name.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        const matches = document.cookie.match(`(?:^|; )${escapedName}=([^;]*)`);
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    function getGaValue() {
        const match = document.cookie.match(/_ga=([^;]*)/);
        const raw = match ? decodeURIComponent(match[1]) : null;
        return raw ? raw.match(/(\d+\.\d+)$/)[1] : null;
    }

    // Function to extract query parameters from the URL
    function getQueryParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    // Function to find the first existing click ID
    function getFirstClickId() {
        const params = ['fbclid', 'gclid', 'li_fat_id', 'msclkid']; // List of query parameter names to check
        return params.reduce((acc, param) => acc || getQueryParam(param), null);
    }

    // Function to collect all required UTM parameters, referral, and the current page URL
    function collectAdditionalParams() {
        const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        const additionalParams = {};
        params.forEach(param => {
            const value = getQueryParam(param);
            if (value !== null) {
                additionalParams[param] = value;
            }
        });
        additionalParams.referrer = document.referrer; // Capture the HTTP referrer
        additionalParams.url = window.location.origin + window.location.pathname; // The sanitized URL without query parameters
        return additionalParams;
    }

    // Function to set contact properties once Tidio is ready
    function onTidioChatApiReady() {
        const clickId = getFirstClickId(); // Get the first existing click ID
        const additionalParams = collectAdditionalParams(); // Collect UTM, referral parameters, and the current page URL

        // Combine click ID (if present) with additional parameters, filtering out null values
        const tidioParams = {...additionalParams, ...(clickId ? {clickid: clickId} : {})};
        Object.keys(tidioParams).forEach(key => tidioParams[key] === null && delete tidioParams[key]);

        // Check if there are any parameters to send
        const keysLength = Object.keys(tidioParams).length;
        if (keysLength > 0) {
            tidioChatApi.setContactProperties(tidioParams);
        }
    }

    // Check if Tidio chat API is available and listen for it to be ready
    if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
    } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }

    get_campaign_info();

    $('div.formWP').append('<div style="display:none"><input type="hidden" name="p" value="">' +
        '<input type="hidden" name="client_id" value="">' +
        '<input type="hidden" name="campaign_source" value="">' +
        '<input type="hidden" name="campaign_channel" value="">' +
        '<input type="hidden" name="campaign_name" value="">' +
        '<input type="hidden" name="campaign_id" value="">' +
        '<input type="hidden" name="ad_group" value="">' +
        '<input type="hidden" name="search_queries" value="">' +
        '<input type="hidden" name="search_terms" value="">' +
        '<input type="hidden" name="session_count" value="">' +
        '<input type="hidden" name="matchtype" value="">' +
        '<input type="hidden" name="device" value="">' +
        '<input type="hidden" name="gclid" value="">' +
        '</div>');

    $('input[name="url_page"]').val(window.location.href);
    $('input[name="client_id"]').val(getGaValue());
    $('input[name="gclid"]').val(getFirstClickId());
    $('input[name="session_count"]').val(session_count);

    // get utm from url
    const searchParams = new URLSearchParams(window.location.search);
    const queryDict = {};

    for (const [key, value] of searchParams.entries()) {
        queryDict[key] = value;
    }

    let utm_list = [
        {key: 'p', sugar: 'p'},
        {key: 'utm_source', sugar: 'campaign_source'},
        {key: 'utm_medium', sugar: 'campaign_channel'},
        {key: 'utm_campaign', sugar: 'campaign_name'},
        {key: 'utm_id', sugar: 'campaign_id'},
        {key: 'utm_term', sugar: 'search_queries'}, //Keyword
        {key: 'utm_content', sugar: 'ad_group'},
        {key: 'term', sugar: 'search_terms'},
        {key: 'matchtype', sugar: 'matchtype'},
        {key: 'device', sugar: 'device'}
    ];

    const date = new Date(new Date().getTime() + 60 * 48 * 3600 * 1000);

    utm_list.forEach(utm_item => {
        utm_item.val = '';

        if (utm_item.key in queryDict) {
            // update cookie val
            document.cookie = `${utm_item.key}=${queryDict[utm_item.key]};path=/;expires=${date.toUTCString()}`;
            utm_item.val = decodeURIComponent(queryDict[utm_item.key]);
        } else {
            // get val from cookie
            const cookieValue = getCookie(utm_item.key);
            if (cookieValue !== null) {
                utm_item.val = cookieValue;
            }
        }

        $(`input[name="${utm_item.sugar}"]`).val(utm_item.val);
    });

    if (window.location.href.indexOf("utm_campaign") > -1) {
        document.cookie = 'url_page' + "=" + window.location.href + ";path=/;expires=" + date.toUTCString();
    }
});
},{}]},{},[1]);
