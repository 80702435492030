(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
/*global $, jQuery, alert, console*/
(function () {
    "use strict";

    $(document).ready(function () {

        if(document.querySelector('#searchContent') !== null) {
            searchAutocomplite();
            addActiveTab();
        }


        $('#searchForm').on('submit', function (e) {
            var searchText = $('#search_input').val();
            e.preventDefault();
            window.location.href = '/search?s=' + searchText + '';
        });

        function addActiveTab() {
            var url = window.location.href;
            if (url.indexOf('&tab=') != '-1') {
                var startIndex = url.indexOf('&tab=') + 5;
                var endIndex;
                var searchTab;
                if(url.split('&tab=')[1].indexOf('&') != '-1') {
                    endIndex = url.split('&tab=')[1].indexOf('&');
                } else {
                    endIndex = 0;
                }

                searchTab = endIndex ? url.slice(startIndex, startIndex + endIndex) : url.slice(startIndex);
            }
        }

        function searchAutocomplite() {
            $('.form_search input').on('input', function() {
                var apiUrl = "https://www.altoros.no/api/elastic/suggest";
                var searchText = $(this).val();

                $('.autocomplite-block').empty();

                apiUrl = apiUrl + '?query=' + searchText + '&count=6';
                $.get(apiUrl).done(function (data) {
                    var resp = JSON.parse(data);

                    let autocomplite = $('.autocomplite-block');

                    resp.length ? autocomplite.show() : autocomplite.hide();

                    resp.forEach(function(val) {
                        $('.autocomplite-block').append("<p class='search-autocompilte-result'>" + val.text + "</p>")
                    })
                }).fail(function() {
                    var message = 'Sorry, an error has occurred, please try again!';
                    // засунуть message куда-нибудь
                    /*alert(message);*/
                });

            });

            $('.form_search').on('click', '.search-autocompilte-result', function () {
                var searchText = $(this).text();
                $('.form_search input').val(searchText);
                $('.autocomplite-block').hide();
            });

            $('body').on('click', function (e) {
                var target = $(e.target);

                if(target.hasClass('search-page-form-input')) return;

                $('.autocomplite-block').hide();
            });
        }

    });
})();
},{}]},{},[1]);
